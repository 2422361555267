.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.max-w-none {
  max-width: none !important;
}

.items-center {
  align-items: center;
}

.uppercase {
  text-transform: uppercase !important;
}

.flex {
  display: flex;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.inline-flex {
  display: inline-flex;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.float-right {
  float: right;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.min-w-auto {
  min-width: auto !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.w-100 {
  width: 100%;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.m-48 {
  margin: 12rem !important;
}

.m-56 {
  margin: 14rem !important;
}

.m-64 {
  margin: 16rem !important;
}

.m-auto {
  margin: auto !important;
}

.m-px {
  margin: 1px !important;
}

.-m-1 {
  margin: -0.25rem !important;
}

.-m-2 {
  margin: -0.5rem !important;
}

.-m-3 {
  margin: -0.75rem !important;
}

.-m-4 {
  margin: -1rem !important;
}

.-m-5 {
  margin: -1.25rem !important;
}

.-m-6 {
  margin: -1.5rem !important;
}

.-m-8 {
  margin: -2rem !important;
}

.-m-10 {
  margin: -2.5rem !important;
}

.-m-12 {
  margin: -3rem !important;
}

.-m-16 {
  margin: -4rem !important;
}

.-m-20 {
  margin: -5rem !important;
}

.-m-24 {
  margin: -6rem !important;
}

.-m-32 {
  margin: -8rem !important;
}

.-m-40 {
  margin: -10rem !important;
}

.-m-48 {
  margin: -12rem !important;
}

.-m-56 {
  margin: -14rem !important;
}

.-m-64 {
  margin: -16rem !important;
}

.-m-px {
  margin: -1px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mx-12 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-16 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.mx-24 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.my-32 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.mx-32 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.mx-40 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.my-48 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.mx-48 {
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.my-56 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.mx-56 {
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.mx-64 {
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mx-px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.-my-1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-my-3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.-mx-3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.-my-4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-mx-4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-my-5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.-mx-5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.-my-6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-mx-6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-my-8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-mx-8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-my-10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.-mx-10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.-my-12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-mx-12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-my-16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-mx-16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-my-20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.-mx-20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.-my-24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.-mx-24 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.-my-32 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.-mx-32 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.-my-40 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.-mx-40 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.-my-48 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.-mx-48 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.-my-56 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.-mx-56 {
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.-my-64 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.-mx-64 {
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.-my-px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.-mx-px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.ml-8 {
  margin-left: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mr-12 {
  margin-right: 3rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.ml-12 {
  margin-left: 3rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.ml-16 {
  margin-left: 4rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mr-20 {
  margin-right: 5rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.ml-20 {
  margin-left: 5rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mr-24 {
  margin-right: 6rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.ml-24 {
  margin-left: 6rem !important;
}

.mt-32 {
  margin-top: 8rem !important;
}

.mr-32 {
  margin-right: 8rem !important;
}

.mb-32 {
  margin-bottom: 8rem !important;
}

.ml-32 {
  margin-left: 8rem !important;
}

.mt-40 {
  margin-top: 10rem !important;
}

.mr-40 {
  margin-right: 10rem !important;
}

.mb-40 {
  margin-bottom: 10rem !important;
}

.ml-40 {
  margin-left: 10rem !important;
}

.mt-48 {
  margin-top: 12rem !important;
}

.mr-48 {
  margin-right: 12rem !important;
}

.mb-48 {
  margin-bottom: 12rem !important;
}

.ml-48 {
  margin-left: 12rem !important;
}

.mt-56 {
  margin-top: 14rem !important;
}

.mr-56 {
  margin-right: 14rem !important;
}

.mb-56 {
  margin-bottom: 14rem !important;
}

.ml-56 {
  margin-left: 14rem !important;
}

.mt-64 {
  margin-top: 16rem !important;
}

.mr-64 {
  margin-right: 16rem !important;
}

.mb-64 {
  margin-bottom: 16rem !important;
}

.ml-64 {
  margin-left: 16rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-px {
  margin-top: 1px !important;
}

.mr-px {
  margin-right: 1px !important;
}

.mb-px {
  margin-bottom: 1px !important;
}

.ml-px {
  margin-left: 1px !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mr-1 {
  margin-right: -0.25rem !important;
}

.-mb-1 {
  margin-bottom: -0.25rem !important;
}

.-ml-1 {
  margin-left: -0.25rem !important;
}

.-mt-2 {
  margin-top: -0.5rem !important;
}

.-mr-2 {
  margin-right: -0.5rem !important;
}

.-mb-2 {
  margin-bottom: -0.5rem !important;
}

.-ml-2 {
  margin-left: -0.5rem !important;
}

.-mt-3 {
  margin-top: -0.75rem !important;
}

.-mr-3 {
  margin-right: -0.75rem !important;
}

.-mb-3 {
  margin-bottom: -0.75rem !important;
}

.-ml-3 {
  margin-left: -0.75rem !important;
}

.-mt-4 {
  margin-top: -1rem !important;
}

.-mr-4 {
  margin-right: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1rem !important;
}

.-ml-4 {
  margin-left: -1rem !important;
}

.-mt-5 {
  margin-top: -1.25rem !important;
}

.-mr-5 {
  margin-right: -1.25rem !important;
}

.-mb-5 {
  margin-bottom: -1.25rem !important;
}

.-ml-5 {
  margin-left: -1.25rem !important;
}

.-mt-6 {
  margin-top: -1.5rem !important;
}

.-mr-6 {
  margin-right: -1.5rem !important;
}

.-mb-6 {
  margin-bottom: -1.5rem !important;
}

.-ml-6 {
  margin-left: -1.5rem !important;
}

.-mt-8 {
  margin-top: -2rem !important;
}

.-mr-8 {
  margin-right: -2rem !important;
}

.-mb-8 {
  margin-bottom: -2rem !important;
}

.-ml-8 {
  margin-left: -2rem !important;
}

.-mt-10 {
  margin-top: -2.5rem !important;
}

.-mr-10 {
  margin-right: -2.5rem !important;
}

.-mb-10 {
  margin-bottom: -2.5rem !important;
}

.-ml-10 {
  margin-left: -2.5rem !important;
}

.-mt-12 {
  margin-top: -3rem !important;
}

.-mr-12 {
  margin-right: -3rem !important;
}

.-mb-12 {
  margin-bottom: -3rem !important;
}

.-ml-12 {
  margin-left: -3rem !important;
}

.-mt-16 {
  margin-top: -4rem !important;
}

.-mr-16 {
  margin-right: -4rem !important;
}

.-mb-16 {
  margin-bottom: -4rem !important;
}

.-ml-16 {
  margin-left: -4rem !important;
}

.-mt-20 {
  margin-top: -5rem !important;
}

.-mr-20 {
  margin-right: -5rem !important;
}

.-mb-20 {
  margin-bottom: -5rem !important;
}

.-ml-20 {
  margin-left: -5rem !important;
}

.-mt-24 {
  margin-top: -6rem !important;
}

.-mr-24 {
  margin-right: -6rem !important;
}

.-mb-24 {
  margin-bottom: -6rem !important;
}

.-ml-24 {
  margin-left: -6rem !important;
}

.-mt-32 {
  margin-top: -8rem !important;
}

.-mr-32 {
  margin-right: -8rem !important;
}

.-mb-32 {
  margin-bottom: -8rem !important;
}

.-ml-32 {
  margin-left: -8rem !important;
}

.-mt-40 {
  margin-top: -10rem !important;
}

.-mr-40 {
  margin-right: -10rem !important;
}

.-mb-40 {
  margin-bottom: -10rem !important;
}

.-ml-40 {
  margin-left: -10rem !important;
}

.-mt-48 {
  margin-top: -12rem !important;
}

.-mr-48 {
  margin-right: -12rem !important;
}

.-mb-48 {
  margin-bottom: -12rem !important;
}

.-ml-48 {
  margin-left: -12rem !important;
}

.-mt-56 {
  margin-top: -14rem !important;
}

.-mr-56 {
  margin-right: -14rem !important;
}

.-mb-56 {
  margin-bottom: -14rem !important;
}

.-ml-56 {
  margin-left: -14rem !important;
}

.-mt-64 {
  margin-top: -16rem !important;
}

.-mr-64 {
  margin-right: -16rem !important;
}

.-mb-64 {
  margin-bottom: -16rem !important;
}

.-ml-64 {
  margin-left: -16rem !important;
}

.-mt-px {
  margin-top: -1px !important;
}

.-mr-px {
  margin-right: -1px !important;
}

.-mb-px {
  margin-bottom: -1px !important;
}

.-ml-px {
  margin-left: -1px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.p-48 {
  padding: 12rem !important;
}

.p-56 {
  padding: 14rem !important;
}

.p-64 {
  padding: 16rem !important;
}

.p-px {
  padding: 1px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.px-24 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.px-32 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.px-40 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.px-48 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.py-56 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.px-56 {
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.px-64 {
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.px-px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pr-8 {
  padding-right: 2rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pr-10 {
  padding-right: 2.5rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pr-12 {
  padding-right: 3rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pl-12 {
  padding-left: 3rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pr-16 {
  padding-right: 4rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pl-16 {
  padding-left: 4rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pr-20 {
  padding-right: 5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pl-20 {
  padding-left: 5rem !important;
}

.pt-24 {
  padding-top: 6rem !important;
}

.pr-24 {
  padding-right: 6rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pl-24 {
  padding-left: 6rem !important;
}

.pt-32 {
  padding-top: 8rem !important;
}

.pr-32 {
  padding-right: 8rem !important;
}

.pb-32 {
  padding-bottom: 8rem !important;
}

.pl-32 {
  padding-left: 8rem !important;
}

.pt-40 {
  padding-top: 10rem !important;
}

.pr-40 {
  padding-right: 10rem !important;
}

.pb-40 {
  padding-bottom: 10rem !important;
}

.pl-40 {
  padding-left: 10rem !important;
}

.pt-48 {
  padding-top: 12rem !important;
}

.pr-48 {
  padding-right: 12rem !important;
}

.pb-48 {
  padding-bottom: 12rem !important;
}

.pl-48 {
  padding-left: 12rem !important;
}

.pt-56 {
  padding-top: 14rem !important;
}

.pr-56 {
  padding-right: 14rem !important;
}

.pb-56 {
  padding-bottom: 14rem !important;
}

.pl-56 {
  padding-left: 14rem !important;
}

.pt-64 {
  padding-top: 16rem !important;
}

.pr-64 {
  padding-right: 16rem !important;
}

.pb-64 {
  padding-bottom: 16rem !important;
}

.pl-64 {
  padding-left: 16rem !important;
}

.pt-px {
  padding-top: 1px !important;
}

.pr-px {
  padding-right: 1px !important;
}

.pb-px {
  padding-bottom: 1px !important;
}

.pl-px {
  padding-left: 1px !important;
}